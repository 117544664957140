<template>
  <div class="auth-wrapper auth-v1 px-2 login-page">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <AppLogoWithText />
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-alert
            v-show="successMessage"
            class="mt-2"
            variant="success"
            show
          >
            <div class="alert-body">
              <span>{{ successMessage }}</span>
            </div>
          </b-alert>
          <b-alert
            v-show="errorMessage"
            class="mt-2"
            variant="danger"
            show
          >
            <div class="alert-body">
              <span>{{ errorMessage }}</span>
            </div>
          </b-alert>

          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmit"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="rememberMe"
                name="checkbox-1"
              >
                Remember me for 30 days
              </b-form-checkbox>
            </b-form-group>
            <vue-programmatic-invisible-google-recaptcha
              ref="recaptcha"
              element-id="recaptcha"
              badge-position="right"
              :sitekey="siteKey"
              :show-badge-mobile="false"
              :show-badge-desktop="true"
              @recaptcha-callback="recaptchaCallback"
            />
            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="loading"
            >
              <div v-if="loading">
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  class="mr-50"
                  small
                  label="Small Spinner"
                  type="grow"
                />
                <b-spinner
                  small
                  label="Small Spinner"
                  type="grow"
                />
              </div>
              <div v-else>
                Sign in
              </div>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-register'}">
            <span>Don't have an account yet? Sign up here.</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'
import AppLogoWithText from '@/components/AppLogoWithText.vue'

export default {
  components: {
    AppLogoWithText,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    VueProgrammaticInvisibleGoogleRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appTextLogoImage } = $themeConfig.app
    return {
      siteKey: '6LfM4KMkAAAAAASqDOHDphXk-Dd7tFVrKQ_xGF1t',
      // siteKey: '6Lf2-DsoAAAAAGlas1-z3ZRspcQ579YnuPB_58JE',
      userEmail: '',
      password: '',
      rememberMe: false,
      loading: false,
      recaptchaResponseToken: null,
      errorMessage: null,
      // validation rules
      required,
      email,
      appTextLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    successMessage() {
      return this.$store.getters['auth/loginFormSuccessMessage']
    },
  },
  methods: {
    validationForm() {
      this.errorMessage = null
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store.dispatch('auth/login', {
            email: this.userEmail,
            password: this.password,
            rememberMe: this.rememberMe,
            recaptcha: process.env.NODE_ENV === 'local' ? 'letmein#' : this.recaptchaResponseToken,
          }).then(
            () => {
              this.$store.commit('auth/resetLoginFormSuccessMessage')
              this.$router.push({ name: 'dashboard' })
            },
            () => {
              this.errorMessage = 'Incorrect email or password.'
              this.loading = false
            },
          ).finally(() => {
            this.loading = false
          })
        }
      })
    },
    showValidationErrors() {
      if (
        this.$refs.loginForm.errors
          && this.$refs.loginForm.errors.email
          && this.$refs.loginForm.errors.email.length
          && this.$refs.loginForm.errors.password
          && this.$refs.loginForm.errors.password.length
      ) {
        this.errorMessage = 'Please enter valid email and password.'
      } else if (this.$refs.loginForm.errors && this.$refs.loginForm.errors.email && this.$refs.loginForm.errors.email.length) {
        const [message] = this.$refs.loginForm.errors.email
        this.errorMessage = message
      } else if (this.$refs.loginForm.errors && this.$refs.loginForm.errors.password && this.$refs.loginForm.errors.password.length) {
        const [message] = this.$refs.loginForm.errors.password
        this.errorMessage = message
      }
    },
    onSubmit() {
      if (process.env.NODE_ENV === 'local') {
        this.validationForm()
        return
      }

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$refs.recaptcha.execute()
        }
      })
    },
    handleError() {
      this.$refs.loginForm.setErrors({
        email: ['Captcha is required'],
      })
    },
    recaptchaCallback(token) {
      if (token) {
        this.recaptchaResponseToken = token
        this.validationForm()
      } else {
        this.handleError()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
